<template>
	<div class="home">
		<h1>{{ ii('SEMALT_TOOLS') }}</h1>

		<div class="home-pages-box">
			<div class="tool-input-box">
				<s-text-field
					v-model="app.query.q"
					ref="tf"
					prepend-icon="search"
					:placeholder="ii('SEARCH')"
					@change="refreshQuery"
				></s-text-field>
			</div>
			<div
				class="home-page-block"
				v-for="page in filteredPages"
				:key="page.id"
			>
				<app-link :to="'/' + page.page">
					<div>
						<strong>{{ ii(page.label) }}</strong>
					</div>
					<div>{{ descMap[page.page] }}</div>
				</app-link>
			</div>
		</div>

		<div class="page-text-content content-block" v-html="markdownToHtml(app.texts.content)"></div>
	</div>
</template>

<style lang="less">
.home {
	margin: 0 auto;
	max-width: 1500px;

	.tool-input-box {
		padding: 20px 5px;
		margin: 0 auto;
		max-width: 500px;
	}

	.home-page-block {
		display: block;
		padding: 5px;

		a {
			display: block;
			padding: 5px;
			border: 1px solid #000;
			background: #fff;
			color: #000;
		}
	}
}
</style>

<script>
	export default {
		data() {
			return {
				descMap: {}
			};
		},
		computed: {
			filteredPages() {
				if (!this.app.query.q) return this.app.pages;

				let searchVal = this.app.query.q.toLowerCase();

				let pages = this.app.pages.filter(page => {
					let title = this.ii(page.label);
					let desc = this.descMap[page.page] || '';

					let labelFound = title.toLowerCase().includes(searchVal);
					let descFound = desc.toLowerCase().includes(searchVal);

					return labelFound || descFound;
				});

				return pages;
			}
		},
		methods: {
			async loadDescriptions() {
				let map = this.app.pagesDescCache[this.app.lang];
				if (!map) {
					map = await this.get('Page.getPagesDescriptions', this.app.lang);
					this.$set(this.app.pagesDescCache, this.app.lang, map);
				}
				this.descMap = map;
			}
		},
		watch: {
			'app.lang'() {
				this.loadDescriptions();
			}
		},
		mounted() {
			this.loadDescriptions();

			if (!this.app.isMob) this.$refs.tf.focus();
		}
	}
</script>